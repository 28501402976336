var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"cr-table-field",class:_vm.rowError.length > 0 ? 'cr-error' : '',attrs:{"item-key":"id","hide-default-footer":"","headers":_vm.headers,"items":_vm.rows,"items-per-page":-1,"item-class":"no-ripple"},on:{"blur":function($event){return _vm.v$.rows.$touch()},"input":function($event){return _vm.v$.rows.$touch()}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [(h.value === 'actions')?_c('div',{key:h.value},[_vm._v("관리")]):_c('div',{key:h.value},[_c('span',[_vm._v(_vm._s(header.text))]),(header.templateFrame.required)?_c('span',{staticClass:"ml-2 accent--text"},[_vm._v(" * ")]):_vm._e()])]}}}),_vm._l((_vm.headers),function(ref){
var fieldId = ref.value;
return {key:("item." + fieldId),fn:function(ref){
var header = ref.header;
var index = ref.index;
var item = ref.item;
return [_c('div',{key:fieldId},[(fieldId === 'actions')?_c('v-btn',{staticClass:"pa-0",attrs:{"icon":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.removeItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1):_c('TemplateFieldItem',{key:fieldId,ref:"column",refInFor:true,attrs:{"templateFrame":header.templateFrame,"editable":_vm.editable},model:{value:(_vm.rows[index][fieldId]),callback:function ($$v) {_vm.$set(_vm.rows[index], fieldId, $$v)},expression:"rows[index][fieldId]"}})],1)]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }