<template>
  <v-data-table
    item-key="id"
    hide-default-footer
    class="cr-table-field"
    :class="rowError.length > 0 ? 'cr-error' : ''"
    :headers="headers"
    :items="rows"
    :items-per-page="-1"
    item-class="no-ripple"
    @blur="v$.rows.$touch()"
    @input="v$.rows.$touch()"
  >
    <!-- 헤더 -->
    <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
      <div v-if="h.value === 'actions'" :key="h.value">관리</div>
      <div v-else :key="h.value">
        <span>{{ header.text }}</span>
        <span v-if="header.templateFrame.required" class="ml-2 accent--text">
          *
        </span>
      </div>
    </template>
    <!-- 아이템 rows [동적 헤더에 따른 렌더링]-->
    <template
      v-for="{ value: fieldId } in headers"
      v-slot:[`item.${fieldId}`]="{ header, index, item }"
    >
      <div :key="fieldId">
        <!-- 관리메뉴 -->
        <v-btn
          v-if="fieldId === 'actions'"
          icon
          color="warning"
          class="pa-0"
          @click.stop="removeItem(item.id)"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <!-- 컬럼 아이템 -->
        <TemplateFieldItem
          v-else
          v-model="rows[index][fieldId]"
          ref="column"
          :key="fieldId"
          :templateFrame="header.templateFrame"
          :editable="editable"
        />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import TemplateFieldItem from "@/approval/views/components/template/TemplateFieldItem";
import useVuelidate from "@vuelidate/core";
export default {
  name: "TableFieldItem",
  components: { TemplateFieldItem },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    value: {
      type: Object,
      description: "필드 데이타 (v-model)"
    },
    templateFrame: {
      type: Object,
      required: true,
      description: "필드 속성"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  data: () => ({
    rows: [],
    sequence: 0 // row.id 가상 발급 용도
  }),
  watch: {
    // rows 변경될 경우 {v-model} 업데이트
    rows: {
      handler(val) {
        // [id, actions] 속성 제거 후 model update
        // eslint-disable-next-line no-unused-vars
        const rows = val.map(({ id, actions, ...row }) => ({ ...row }));
        this.$emit("input", { rows: rows });
      },
      deep: true
    }
  },
  computed: {
    // 테이블 헤더 목록
    headers() {
      const dynamicHeaders = this.templateFrame?.columns.map(column => ({
        value: column.id,
        text: column.name,
        align: "start",
        sortable: false,
        templateFrame: column
      }));
      const actionHeader = {
        value: "actions",
        text: "Actions",
        align: "center",
        sortable: false
      };
      return [...dynamicHeaders, ...(this.editable ? [actionHeader] : [])];
    },
    rowError() {
      const { $dirty, required } = this.v$.rows;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("최소 1개 행 이상 작성해야 합니다.");
      return errors;
    }
  },
  methods: {
    // 상위 컴포넌트에서 호출용도
    btnAction() {
      if (!this.editable) return;
      this.addItem();
    },
    // 행 추가
    addItem() {
      this.rows = [...this.rows, { id: ++this.sequence }];
    },
    // 행 제거
    removeItem(itemId) {
      this.rows = this.rows.filter(({ id }) => id !== itemId);
    },
    async validate() {
      await this.v$.$validate();
      // 테이블에 대한 유효성 검사 (필수행 누락등)
      const tableValid = !this.v$.$invalid;
      // 테이블 내부 컬럼들에 대한 유효성 검사
      const checkColumnValid = async () => {
        let allValid = true;
        for (const ref of this.$refs.column) {
          const columnValid = await ref.validate();
          if (!columnValid) allValid = false;
        }
        return allValid;
      };
      return tableValid && checkColumnValid();
    }
  },
  validations() {
    return {
      rows: {
        required: val => {
          if (!this?.templateFrame?.required) return true;
          return val?.length > 0;
        }
      }
    };
  },
  mounted() {
    // 기본값 초기화 (수정일 경우 기존값 할당)
    this.$nextTick(() => {
      if (this.value?.rows) {
        this.value.rows.forEach((row, idx) =>
          this.$set(this.rows, idx, { ...row, id: ++this.sequence })
        );
      } else {
        this.$emit("input", { rows: [] });
      }
    });
  }
};
</script>

<style lang="scss" scoped>
// 필수 행 누락시 에러표시
.cr-table-field::v-deep {
  &.cr-error {
    border: 1px solid red !important;
  }
  tbody {
    tr:hover {
      background: rgb(210 210 210 / 10%) !important;
    }
  }
}
</style>
